import React, { useEffect } from "react";
import wineGlass from "../../img/wine-glass.png";
import AddHinge from "../mecanizado/hinge/AddHinge";
import { Route, useParams } from "react-router-dom";
import "./Preview.css";
import "../mecanizado/hinge/AddHinge.scss";
import { connect } from "react-redux";
import { Container, Row, Col } from "react-bootstrap";
import bottom_hf from "../../img/hf_inferior.jpg";
import top_hf from "../../img/hf_superior.jpg";
import bottom_hf_tri from "../../img/hf_inferior_tri.jpg";
import top_hf_tri from "../../img/hf_superior_tri.jpg";
import slider from "../../img/corredera.jpg";

const Preview = ({
  open,
  size,
  filling,
  sash,
  add_hinge_boolean,
  mechanized,
  blackFilm,
  ...props
}) => {
  const params = useParams();

  useEffect(() => {
    isHingesStep();
  }, []);

  const getTranslate = () => {
    if (parseInt(window.innerWidth) <= 1024) {
      return "translateX(-17%) translateY(-12%)";
    }
    return "translateX(-22%) translateY(-15%)";
  };

  const sashImageStyles = () => {
    const previewEl = document.getElementById("id-filling-color");
    if (!!previewEl) {
      //TODO if top,bottom do this.

      if (size !== "normal" && (open === "Top" || open === "Bottom")) {
        return {
          width: previewEl.clientHeight,
          height: previewEl.clientWidth,
          transform: `rotate(90deg) ${getTranslate()}`,
        };
      }
    }
    return {
      width: "100%",
      height: "100%",
    };
  };
  const isHingesStep = () => {
    const pathSep = props.location.pathname.split("/");
    return pathSep.includes("profile") && !pathSep.includes("hinge");
  };
  const canSelectHinges = () => {
    return open !== "Avento" && open !== "Slider"; 
  };
  const renderMechanized = () => {
    let imgUrl = top_hf;
    let leyend = [
      "* A: Destaje para bisagra de ajuste en mueble.",
      "* B: Destaje para bisagra de ajuste con puerta inferior.",
    ];
    if (parseInt(params.width) > 1200) { imgUrl = top_hf_tri}
    // console.log('Mechanized ====<', mechanized)
    if (mechanized.value === "BOTTOM_HF") {
      leyend = [
        "* A: Destaje para bisagra de unión con puerta superior",
        "* B: Destaje para unión con brazo mecánico.",
      ];
      imgUrl = bottom_hf
      if (parseInt(params.width) > 1200) { imgUrl = bottom_hf_tri }
    }
    if (mechanized.value === "SLIDER_RP50") {
      leyend = ["* A: Destaje para ruedas."];
      imgUrl = slider;
    }
    return (
      <div className='mechanized'>
        <img src={imgUrl} />
        {leyend.map((item) => (
          <p>{item}</p>
        ))}
      </div>
    );
  };
  if (isHingesStep()) {
    if (canSelectHinges()) {
      return (
        <div
          id='divHing'
          className={`justify-content-center preview-hinge-${size.toLowerCase()}-${open.toLowerCase()} was-validated grid-hinge-container`}
          // style={{"marginLeft": open==='Left'? '4rem' : ''}}
        >
          <img
            src={`${sash.previewPhoto}`}
            className='img-preview-grid '
            style={sashImageStyles()}
          />
          {/* <img
            src={filling.url}
            className='img-preview-grid filling-container'
          /> */}
          {add_hinge_boolean && (
            <Route
              exact
              path='/client/type/:type/open/:open/width/:width/height/:height/line/:line/line_sku/:line_sku/nameColor/:nameColor/blackFilm/:blackFilm/profile/:profile'
              component={AddHinge}
            />
          )}
        </div>
      );
    }
    console.log('Mechanized: ', mechanized)
    if (
      mechanized?.value === "SLIDER_RP50" ||
      mechanized?.value === "TOP_HF" ||
      mechanized?.value === "BOTTOM_HF"
    )
      return renderMechanized();
  } else {
    console.log('Preview Photop: ', sash.previewPhoto)
    return (
      <Col>
        <div className='preview-container'>
          <div
            id='preview-window'
            className={`border-preview-${open.toLowerCase()} preview-window preview-${size} ${
              sash.sku !== "" ? "preview-sash-width" : ""
            }`}
          >
            <div className='principal-preview'>
              <img src={`${sash.previewPhoto}`} style={sashImageStyles()} />
            </div>
            {/* <img
              id='id-filling-color'
              src={filling.url}
              className='filling-container principal-preview'
            /> */}
          </div>
        </div>
      </Col>
    );
  }
  return <></>
};

const mapStateToProps = ({ open, mechanized, size, filling, sash, hinges }) => ({
  open,
  size,
  filling,
  sash,
  mechanized,
  add_hinge_boolean: hinges.add_hinge_boolean,
});

const mapDispatchToProps = (dispach) => ({});

const wrapper = connect(mapStateToProps, mapDispatchToProps);
const component = wrapper(Preview);

export default component;
